// padding and margin
.p0 {
  padding: 0 !important;
}

.p-24 {
  padding: 24px;
}

.p20 {
  padding: 20px !important;
}

// width list
.w {
  width: 100% !important;
}

.w250 {
  width: 300px;
}

// list fontsize
.fs14 {
  font-size: 14px !important;
}

.fs16 {
  font-size: 16px;
}

.fs18 {
  font-size: 18px !important;

  .fs-22 {
    font-size: 22px !important;
  }
}

.fs26 {
  font-size: 26px !important;
}

.fs28 {
  font-size: 28px !important;
}

.fs36 {
  font-size: 34px;
}

.fw400 {
  font-weight: 400 !important;
}

.fw600 {
  font-weight: 600;
}

.getStartContainer {
  border: 1px solid red;
}

.getStartedtitle>p {
  font-size: 22px;
  font-weight: 600;
}

.createProjectBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.newOpacity {
  opacity: 0.4 !important;
}

.custHeight {
  height: 10px;
  background-color: red !important;
}

.custCardPad>.card-header {
  min-height: unset !important;
  border: 1px solid #DBDEE5 !important;
}

.makeCenterModal {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.invitemainContainer,
.createProjContainer {
  position: relative;
}

.emailIcons {
  position: absolute;
  left: 12px;
  font-size: 30px;
}

.editorStyle {
  width: 100px;
  width: 145px;
  right: 5px;
  top: 4px;
  position: absolute;
}

.projectfieldIcon {
  position: absolute;
  left: 13px;
  top: 4px;
}

.createProjContainer>input {
  padding-left: 48px !important;
}

.folderNameCont {
  min-height: 50px;
  width: 100%;
  // border: 1px solid red;
  border-radius: 7px;

  .card {
    cursor: pointer;
  }
}

.efect:hover {
  cursor: pointer;
}

.serviceEffect {
  padding: 15px;
  border: 1px solid #eee;
  box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px;
  transition: all 200s ease-in-out;

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.11) 0px 5px 5px;
    transform: translate3d(0px, -1px, 0px);
    cursor: pointer;
  }
}

.SelectServiceColor {
  border: 1px solid red;
}

.voiceIconContainer {
  top: 9px;
  right: 10px;
}

.addMoreBorder {
  border: 1px solid;
  border-radius: 40px;
}

.playBtn {
  position: absolute;
  left: 60px;
  top: 10px;
}

.customModalSize {
  min-width: 95vw !important;
  max-width: 95vw !important;
}

.genderCon {
  border-radius: 20px !important;
}

.searchIconsStyle {
  top: 5px;
  left: 10px;
}

.searchBox {
  height: 35px !important;
}

.heartIcons {
  top: -7px;
  left: 3px;
}

.previewSound {
  top: -7px;
  right: 0;
}

.custPad {
  .px-5 {
    padding-top: 2px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

.workSpaceText {
  border-radius: 8px;
}

.was-validated .form-select:valid:not([multiple]):not([size]),
.was-validated .form-select:valid:not([multiple])[size='1'],
.form-select.is-valid:not([multiple]):not([size]),
.form-select.is-valid:not([multiple])[size='1'] {
  padding-right: 0 !important;
}

.setingPitch {
  border-radius: 20px;
  cursor: pointer;
}

.slider {
  width: 60%;
  margin: 10px auto;
  -webkit-appearance: none;
  height: 8px;
  border-radius: 4px;
  margin-bottom: 15px;
  background-color: rgb(200, 200, 200);
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 18px;
  height: 18px;
  border-radius: 10px;
  background-color: #04c8c8;
  overflow: visible;
  cursor: pointer;
}

.selected_voice {
  border: 2px solid #00AFAF;
  border-radius: 10%;
  border-radius: 12px;
  padding: 0px;
}

.show_ed {
  display: block !important;
  opacity: 1 !important;
  visibility: visible !important;
  transform: translateX(0) !important;
  position: absolute !important;
}

.fi_bu {
  position: fixed;
  bottom: 0;
}

.emp_f {
  color: slategray;
  font-size: xx-large;
}

.mainslider {
  overflow-y: scroll;
  height: 280px;
}

.mainslider::-webkit-scrollbar-thumb {
  background: #00AFAF;
  border-radius: 10px;
}

.mainslider::-webkit-scrollbar {
  width: 8px;
}

.mainslider::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

@media (min-width: 992px) {

  main::-webkit-scrollbar-thumb,
  pre::-webkit-scrollbar-thumb,
  div::-webkit-scrollbar-thumb {
    background-color: #00AFAF !important;
  }
}

.createheading p {
  font-size: 18px;
  font-weight: 600;
  color: slategray;
  margin-top: 28px;
  margin-bottom: 15px;
}

@media (max-width:768px) {
  .mainslider {
    overflow-y: scroll;
    height: 144px;
  }

  .createheading p {
    font-size: 14px;
  }
}

@media (min-width:768px) and (max-width:1300px) {
  .mainslider {
    overflow-y: scroll;
    height: 175px;
  }
}

@media (min-width:576px) and (max-width:768px) {
  .mainslider {
    overflow-y: scroll;
    height: 202px;
  }
}

@media (min-width:1300px) {
  .createcont.container {
    width: 1465px !important;
    max-width: 1465px !important;
  }
}

.renameinpt input {
  border: none;
  outline: none;
  padding: 0px;
}

.renameinpt {
  margin-top: 10px;
}

.primarybtn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.primarybtn button {
  margin-left: 10px;
}

.removeaddbtn {
  width: 100% !important;
}

.selectfield.form-select.is-invalid:not([multiple]):not([size]) {
  padding-right: 0px !important;
}

.renametxt {
  text-align: center;
}

.allrename {
  display: flex;
  align-items: center;
  justify-content: center;
}

.allcardsections.card .card-body {
  padding: 0px !important;
}

.accountInfo {
  margin-top: 35px;
  margin-bottom: 25px;
}

.accountInfo h1 {
  color: #22c8c7;
}

.accountInfo h2 span {
  color: #1ba0f4;
}

.orgList .card-body {
  padding: 18px 18px;
}

.alltrgtdtls {
  padding: 4px 15px 15px 15px;
}

.addmorebtn {
  margin-bottom: 100px;
}

.allradiobtn {
  // display: flex;
  // align-items: flex-start;
  // justify-content: flex-start;
  // text-align: start;
}

.indradiosection {
  display: flex;
  align-items: center;
  // justify-content: center;
  font-size: 14px;
}

.indradiosection input {
  margin-right: 5px;
}

.resetpassword p {
  font-weight: 500;
  font-size: 14px;
}

.nextbtn {
  display: flex !important;
  align-items: center;
  justify-content: flex-end;
}

.cardactive {
  border: 2px solid #00AFAF;
  padding: 0px;
  border-radius: 10px;
  height: 100%;
}

.addaccountsec:hover {
  background-color: var(--bs-primary) !important;
}

.addaccountsec:hover p {
  background-color: var(--bs-primary) !important;
  color: white;
}

.setpassword {
  position: relative;
}

.passbutton {
  position: absolute;
  top: 28px;
  right: 0;
  margin-right: 15px;
}

.loginsetpassword {
  position: relative;
}

.loginpassbutton {
  position: absolute;
  top: 0;
  right: 0;
  margin-right: 12px;
}

.passbutton i,
.loginpassbutton i {
  font-size: 15px;
}

@media (min-width:890px) and (max-width:924px) {
  .createProjectBtn {
    min-height: 140px;
  }
}

@media (min-width:992px) and (max-width:1054px) {
  .createProjectBtn {
    min-height: 188px;
  }
}

.allRecntProject:hover {
  box-shadow: rgba(0, 0, 0, 0.11) 0px 5px 5px;
  // transform: translate3d(0px, -1px, 0px);
  cursor: pointer;
}

.allFolder {
  border: 1px solid #DBDEE5;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
}

.allFolder:hover {
  box-shadow: rgba(0, 0, 0, 0.11) 0px 5px 5px;
  // transform: translate3d(0px, -1px, 0px);
  cursor: pointer;
}

.dashBordSection {
  background-color: #DBDEE5;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dashBoardRecent img {
  height: 80px;
}

.allRecntProject {
  height: 198px !important;
}

.allcardsections {
  position: relative;
}

.removeaddbtn .closeSec {
  position: absolute;
  right: 0px;
  top: 0px;
  padding: 0px !important;
  background-color: #22c8c7;
  border-radius: 12px;
}

.removeaddbtn .closeSec svg {
  color: #ffffff;
  height: 16px !important;
  width: 16px !important;
}

.alltrgtdtls .menu.menu-sub {
  right: 0px;
}

.playSlider button {
  border: 1px solid #2a7c7b21 !important;
}

.folderAddUpr {
  margin-top: 20px;
}

h1.folderAdd {
  color: slategray;
  font-size: 20px;
  margin-bottom: 16px;
}

.card.allRecntProject,
.card.allFolder {
  box-shadow: none;
}

.card.allRecntProject:hover,
.card.allFolder:hover {
  box-shadow: rgba(0, 0, 0, 0.11) 0px 5px 5px;
}

input[type="password"]::-ms-reveal {
  display: none;
}

@media(max-width:383px) {
  .mainslider .serviceEffect {
    min-height: 139px;
    text-align: center;
  }
}

.alldashdrop {
  width: 260px !important;
}

.PriceDropdown {
  border-bottom: 1px solid gray;
  margin: 5px 15px;
}

.dreopdownIcon1 i {
  font-size: 15px;
  color: var(--bs-text-gray-700) !important;
}

.dreopdownIcon i {
  font-size: 13px;
  color: var(--bs-text-gray-700) !important;
}


.mobileSoundSerch {
  display: none;
}

@media(max-width:991px) {
  .soundSerch {
    display: none;
  }

  .mobileSoundSerch {
    display: unset;
    border-bottom: 1px solid #DBDEE5;
  }

  .allSearchField {
    --bs-gutter-x: 0rem;
  }

  .loginResponse {
    display: unset !important;
  }

  .allLoginForm {
    --bs-gutter-x: 0.8rem;
  }

  .downPlayBtn {
    width: 90% !important;
  }
}

@media(min-width:576px) and (max-width:991px) {
  .searchBox {
    height: 40px !important;
  }

  .serchFieldLft {
    margin-right: 10px;
  }

  .searchFieldRgt {
    margin-right: 5px;
  }

  .searchFieldLft {
    margin-left: 5px;
  }
}

.allCheckedSec .form-switch.form-check-solid .form-check-input:not(:checked) {
  background-color: #5E6278;
}

.rcntLines,
.folderLines,
.projectLines,
.manageTopSrch,
.findFolderTop {
  position: relative;
}

.rcntLines::after {
  content: "";
  position: absolute;
  margin: auto;
  height: 30px;
  background: #ffff;
  width: 1px;
  top: -5px;
  left: 145px;
}

.folderLines::after {
  content: "";
  position: absolute;
  margin: auto;
  height: 30px;
  background: #ffff;
  width: 2px;
  top: -5px;
  left: 107px;
}

.projectLines::after {
  content: "";
  position: absolute;
  margin: auto;
  height: 30px;
  background: #ffff;
  width: 2px;
  top: -5px;
  left: 114px;
}

// .projectBorder{
//   background-color: #eeeff2;
// }
.inviteInput input {
  padding-right: 155px;
}

.inviteInput .form-control.is-invalid {
  padding-right: 155px;
}

.account_selected {
  border: 2px solid lightseagreen;
  border-radius: 10px;
}

@media(max-width:575px) {
  .managePading {
    padding: 0px 0px 20px 0px !important;
  }

  .signCamp {
    margin-bottom: 25px !important;
  }

  // .separator{
  //   margin: 30px 0px!important;
  // }
  .manageLogin {
    margin-bottom: 9px !important;
  }

  .continueBtn {
    margin-bottom: 20px !important;
  }

  .passwordResponse {
    display: unset !important;
  }
}

.emailVerifySec {
  text-align: center;
  margin: 0 auto;
}

.createFolder {
  border: 1px solid var(--bs-gray-300);
  padding: 11px 10px;
  border-radius: 6px;
}

.maincreateFolder span {
  padding: 38px;
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 1.5;
  color: var(--bs-gray-400);
}

.allNewFolder {
  // background-color:#ffff;
  position: absolute;
  z-index: 1;
  border-radius: 5px;
  width: 100%;
}

.folderData {
  font-size: 14px;
  font-weight: 500;
  padding: 12px;
  display: block;
  text-align: unset;
  padding: 0px !important;
  margin: 6px;
}

.folderData:hover {
  color: #22c8c7;
}

.midNewFolder {
  border: 1px solid #22c8c7;
  border-radius: 5px;
  animation: menu-sub-dropdown-animation-fade-in 0.3s ease 1, menu-sub-dropdown-animation-move-up 0.3s ease 1;
  box-shadow: var(--bs-menu-dropdown-box-shadow);
}

.manageBotmSrch {
  position: absolute;
  right: 0;
  margin-right: 15px;
  top: 15px;
}

.ind_download {
  position: absolute;
  top: 50%;
}

@media(min-width:991px) and (max-width:1300px) {
  .downPlayBtn {
    width: 65% !important;
  }
}

.card.dashbordFolder {
  width: 100%;
  padding: 20px 15px;
  border-radius: 8px 0 0 8px !important;
}

.dashThreeDot {
  background-color: #ffff;
  padding: 13px 4px;
  border-radius: 0px 8px 8px 0;
}

.formError {
  color: var(--bs-danger);
}

.findFolderBotm {
  position: absolute;
  top: 4px;
  right: 10px;
}

.findFolderTop i {
  font-size: 15px;
}

.createFolderSlider {
  overflow-y: scroll;
  height: 140px;
  margin-top: 10px;
}

.createFolderSlider::-webkit-scrollbar {
  width: 6px;
}

.createFolderSlider::-webkit-scrollbar-track {
  box-shadow: none;
  border-radius: 10px;
}

.createNewModal.modal.show .modal-dialog {
  display: flex;
  align-items: center;
  justify-content: center;
}

.errorImgSec,
.dashboardPrjctSec {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 5px 0px;
}

.errorImgSec i {
  color: red;
  margin-right: 8px;
  font-size: 18px;
}

.dashboardPrjctSec i {
  font-size: 18px;
  color: #ffff;
}

.dashboardPrjctSec {
  background-color: #22c8c7;
  width: 100%;
  // margin: 15px 20px;
  border-radius: 6px;
  padding: 10px 8px;
  ;
}

.dashboardPrjctSec h4 {
  color: #ffff !important;
}

.allDashbordBodr {
  background-color: #ffff;
  padding: 10px;
  border-radius: 10px;
  // background-color: var(--bs-primary-light)
}

.removeBaground {
  --bs-btn-hover-bg: none;
  --bs-btn-active-bg: none;
}

.manageFolderSrch {
  position: absolute;
  right: 0;
  margin-right: 15px;
  top: 22px;
}

.prjctPagination button {
  border: none;
  outline: none;
  margin: 0px 5px;
  padding: 5px 12px;
  border-radius: 6px;
  font-weight: bold;
  background-color: #04c8c8;
}

.page-item {
  margin-right: 0px !important;
}


.roundPagination .css-10w330c-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
  background-color: #04c8c8 !important;
  color: #ffff;
}
.allTermsPage hr {
  margin: 25px;
}
.allTermsPage p {
  font-size: 14px;
  font-weight: 500;
}
button.selectModalBtn {
  padding: 0px !important;
  border: none !important;
}
.pricingPlanBox {
  border: 1px solid #cdced3;
}
.pricingBtns {
  margin-top: 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.pricingBtns button {
  background-color: #04c8c8;
  border: 0px;
  color: #ffffff;
  font-size: 15px;
  font-weight: 600;
  cursor: pointer;
  padding: 4px 12px;
  min-width: 104px;
  border-radius: 2px;
}
.active-plan {
  max-width: 58%;
  margin: 0px auto;
  margin-bottom: 42px;
  background-color: #fff;
  padding: 20px 30px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgb(49 200 198);
  border: 1px solid #31c8c6;
}
.activePlanUpr h2 {
  margin-top: 0;
  text-align: center;
  font-size: 24px;
  margin-bottom: 20px;
  padding-bottom: 12px;
  border-bottom: 2px solid #22c8c7;
}
.activePlanUpr .plan-details {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.activePlanUpr label {
  font-weight: 500;
  font-size: 15px;
}
.activePlanUpr span {
  display: block;
  font-size: 15px;
}
@media (max-width: 768px) {
  .active-plan {
    max-width: 100% !important;
  }
}
.availableTpUpr {
  text-align: left;
}
.availableTp {
  margin-bottom: 21px;
}
.availableTp label {
  font-size: 20px;
  font-weight: 500;
  display: block;
  margin-bottom: 10px;
}
.availableTp input[type="text"] {
  width: 100%;
  padding: 7px 12px;
  border-radius: 2px;
  border: 1.5px solid #d0cdcd;
}
.availableTpUpr button {
  background-color: #04c8c8;
  border: 0px;
  color: #ffffff; 
  font-size: 15px;
  font-weight: 600;
  cursor: pointer;
  padding: 4px 12px;
  min-width: 104px;
  border-radius: 2px;
}
.selectPricing h3 {
  font-size: 20px;
  margin-bottom: 7px;
}
label.AvailTpup {
  cursor: pointer;
}
.billing-container {
    background-color: #fff;
}
.billing-container h2, .billing-container h3 {
  margin-top: 0;
  text-align: center;
  margin-bottom: 18px;
}
.billing-container .preview, .billing-container .payment-details {
  margin-bottom: 15px;
}
.preview .preview-item, .payment-details .item {
  display: flex;
  justify-content: space-between;
}
.preview .preview-item .item-label {
  font-weight: bold;
}
.payment-details .total-amount {
  margin-top: 10px;
  font-weight: bold;
}   
.tooltip-container {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.tooltip-content {
  display: none;
  position: absolute;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 10px;
  max-width: 300px;
  z-index: 1;
  top: 100%; /* Position it below the parent element */
  left: 0;
}

.tooltip-container:hover .tooltip-content {
  display: block;
}
.tooltip-container::before {
  content: "";
  position: absolute;
  top: -15px; /* Adjust to position the balloon above the container */
  left: 50%;
  transform: translateX(-50%);
  border-width: 8px;
  border-style: solid;
  border-color: transparent transparent #fff transparent;
}

.flash-message {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: red;
  color: white;
  padding: 10px;
  text-align: center;
}


.ActivePlan{
  max-width: 58%;
  margin: 0px auto;
  margin-bottom: 42px;
  padding: 20px 30px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgb(49, 200, 198);
  border: 1px solid #31c8c6;
}